
// @ts-nocheck


export const localeCodes =  [
  "en",
  "zh"
]

export const localeMessages = {
  "en": [{ key: "../src/locales/messages/en.json", load: () => import("../src/locales/messages/en.json" /* webpackChunkName: "locale_D_58_D_58_Project_vue_frontend_new_src_locales_messages_en_json" */), cache: true }],
  "zh": [{ key: "../src/locales/messages/zh.json", load: () => import("../src/locales/messages/zh.json" /* webpackChunkName: "locale_D_58_D_58_Project_vue_frontend_new_src_locales_messages_zh_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/locales/i18n.config.ts?hash=abf1162b&config=1" /* webpackChunkName: "src_locales_i18n_config_ts_abf1162b" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "jsTsFormatResource": true,
    "restorestate": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "src/locales/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/messages/en.json"
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "name": "简体中文",
      "files": [
        "locales/messages/zh.json"
      ]
    }
  ],
  "defaultLocale": "zh",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales/messages",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://feee.io",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        {
          "path": "locales/messages/en.json"
        }
      ]
    },
    {
      "code": "zh",
      "iso": "zh-CN",
      "name": "简体中文",
      "files": [
        {
          "path": "locales/messages/zh.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

